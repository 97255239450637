import React from 'react'
import Navbar from '../component/Navbar'

export default function CartDetail() {
  return (
    <div>
        <Navbar/>
    </div>
  )
}
