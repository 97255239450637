import React from "react";
import { GiTomato } from "react-icons/gi";
import { GiAubergine } from "react-icons/gi";
import { FaAppleWhole } from "react-icons/fa6";
import { GiSuperMushroom } from "react-icons/gi";
import { GiStrawberry } from "react-icons/gi";
import { LiaCheeseSolid } from "react-icons/lia";
import { GiOrange } from "react-icons/gi";
import { FaCarrot } from "react-icons/fa6";
import { GiLeek } from "react-icons/gi";
import { LuBanana } from "react-icons/lu";
import { CiLemon } from "react-icons/ci";
import { GiCorn } from "react-icons/gi";
import { Link } from "react-router-dom";

export default function NewArrivals() {
  return (
    <div className="w-full h-full mb-16">
      <div className="flex flex-col w-full md:h-80 h-[520px] shadow-lg bg-gray-100  ">
        <div class="flex ...">
          <div class="flex-none md:w-32 h-14 ...">{/* 01 */}</div>
          <div class="grow h-14 ...">
            <div className="text-left font-display mt-6 text-green-900 text-4xl ml-5">
              Malzemeye Göre Tarifler
            </div>
            <div className="flex justify-center">
              <div class="grid md:grid-cols-6 grid-cols-2 ıcğwl gap-4 mt-6">
                <div className="border-2 w-40 h-16 bg-white shadow rounded-lg hover:border-green-900 duration-300 ">
                  <Link to={"malzemeye-gore-tarifler/domatesli-tarifler"}>
                    <div className="flex flex-row justify-center mt-2">
                      <div>
                        <GiTomato size={40} color="red" />
                      </div>
                      <div className="text-xl mt-2 font-display2 ml-2 text-slate-400 font-semibold">
                        Domatesli
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="border-2 w-40 h-16 bg-white shadow rounded-lg hover:border-green-900 duration-300 ">
                  <Link to={"malzemeye-gore-tarifler/patlıcanlı-tarifler"}>
                    <div className="flex flex-row justify-center mt-2">
                      <div>
                        <GiAubergine size={40} color="purple" />
                      </div>
                      <div className="text-xl mt-2 font-display2 ml-2 text-slate-400 font-semibold">
                        Patlıcanlı
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="border-2 w-40 h-16 bg-white shadow rounded-lg hover:border-green-900 duration-300 ">
                  <Link to={"malzemeye-gore-tarifler/elmalı-tarifler"}>
                    <div className="flex flex-row justify-center mt-2">
                      <div>
                        <FaAppleWhole size={40} color="green" />
                      </div>
                      <div className="text-xl mt-2 font-display2 ml-2 text-slate-400 font-semibold">
                        Elmalı
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="border-2 w-40 h-16 bg-white shadow rounded-lg hover:border-green-900 duration-300 ">
                  <Link to={"malzemeye-gore-tarifler/mantarlı-tarifler"}>
                    <div className="flex flex-row justify-center mt-2">
                      <div>
                        <GiSuperMushroom size={40} color="grey" />
                      </div>
                      <div className="text-xl mt-2 font-display2 ml-2 text-slate-400 font-semibold">
                        Mantarlı
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="border-2 w-40 h-16 bg-white shadow rounded-lg hover:border-green-900 duration-300 ">
                  <Link to={"malzemeye-gore-tarifler/cilekli-tarifler"}>
                    <div className="flex flex-row justify-center mt-2">
                      <div>
                        <GiStrawberry size={40} color="red" />
                      </div>
                      <div className="text-xl mt-2 font-display2 ml-2 text-slate-400 font-semibold">
                        Çilekli
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="border-2 w-40 h-16 bg-white shadow rounded-lg hover:border-green-900 duration-300 ">
                  <Link to={"malzemeye-gore-tarifler/peynirli-tarifler"}>
                    <div className="flex flex-row justify-center mt-2">
                      <div>
                        <LiaCheeseSolid size={40} color="grey" />
                      </div>
                      <div className="text-xl mt-2 font-display2 ml-2 text-slate-400 font-semibold">
                        Peynirli
                      </div>
                    </div>{" "}
                  </Link>
                </div>
                <div className="border-2 w-40 h-16 bg-white shadow rounded-lg hover:border-green-900 duration-300 ">
                  <Link to={"malzemeye-gore-tarifler/portakallı-tarifler"}>
                    <div className="flex flex-row justify-center mt-2">
                      <div>
                        <GiOrange size={40} color="orange" />
                      </div>
                      <div className="text-xl mt-2 font-display2 ml-2 text-slate-400 font-semibold">
                        Portakallı
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="border-2 w-40 h-16 bg-white shadow rounded-lg hover:border-green-900 duration-300 ">
                  <Link to={"malzemeye-gore-tarifler/mısırlı-tarifler"}>
                    <div className="flex flex-row justify-center mt-2">
                      <div>
                        <GiCorn size={40} color="yellow" />
                      </div>
                      <div className="text-xl mt-2 font-display2 ml-2 text-slate-400 font-semibold">
                        Mısırlı
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="border-2 w-40 h-16 bg-white shadow rounded-lg hover:border-green-900 duration-300 hidden md:block">
                  <Link to={"malzemeye-gore-tarifler/pırasalı-tarifler"}>
                    <div className="flex flex-row justify-center mt-2">
                      <div>
                        <GiLeek size={40} color="green" />
                      </div>
                      <div className="text-xl mt-2 font-display2 ml-2 text-slate-400 font-semibold">
                        Pırasalı
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="border-2 w-40 h-16 bg-white shadow rounded-lg hover:border-green-900 duration-300 hidden md:block">
                  <Link to={"malzemeye-gore-tarifler/muzlu-tarifler"}>
                    <div className="flex flex-row justify-center mt-2">
                      <div>
                        <LuBanana size={40} color="yellow" />
                      </div>
                      <div className="text-xl mt-2 font-display2 ml-2 text-slate-400 font-semibold">
                        Muzlu
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="border-2 w-40 h-16 bg-white shadow rounded-lg hover:border-green-900 duration-300  hidden md:block">
                  <Link to={"/malzemeye-gore-tarifler/havuclu-tarifler"}>
                    <div className="flex flex-row justify-center mt-2">
                      <div>
                        <FaCarrot size={40} color="orange" />
                      </div>
                      <div className="text-xl mt-2 font-display2 ml-2 text-slate-400 font-semibold">
                        Havuçlu
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="border-2 w-40 h-16 bg-white shadow rounded-lg hover:border-green-900 duration-300 hidden md:block">
                  <Link to={"malzemeye-gore-tarifler/limonmu-tarifler"}>
                    <div className="flex flex-row justify-center mt-2">
                      <div>
                        <CiLemon size={40} color="yellow" />
                      </div>
                      <div className="text-xl mt-2 font-display2 ml-2 text-slate-400 font-semibold">
                        Limonlu
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>{" "}
            <div className="flex justify-center ">
              <Link to={"/malzemeye-gore-tarifler"}>
                <div
                  className="w-64 bg-green-900 hover:bg-black text-white font-display2
              py-3 px-10 rounded transition duration-500 ease-in-out transform
              hover:scale-110 mt-4 mb-4"
                >
                  {" "}
                  Daha Fazla Gör
                </div>
              </Link>
            </div>
          </div>
          <div class="flex-none md:w-32 h-14 ...">{/* 03 */}</div>
        </div>
      </div>
    </div>
  );
}
