import React from "react";

export default function CompanyName() {
  return (
    <div>
      <div className="flex flex-col">
        <div className=" md:text-6xl  text-3xl font-display3 text-black]">
          Mizanplas.net
        </div>
        <div className="mt-2 md:text-xl text:md font-display text-green-900 -skew-y-6 shadow">
          yemek için, herşey
        </div>
      </div>
    </div>
  );
}
