import React, { useState, useEffect } from "react";
import people from "../api/data";
import Slider from "react-slick";

export default function Writer() {
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(people);
  }, []);

const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000
  };

  return (
<div className="bg-green-900 w-full h-full mb-16 shadow-xl">

</div>
  );
}
