const people = [
    {
      id: 1,
      image:
        'https://res.cloudinary.com/dd4d48hwn/image/upload/v1701931821/WhatsApp_Image_2023-12-07_at_08.53.34_bck5ow.jpg',
      name: 'Mehmet ASLANTAŞ',
      title: 'Kurucu',
      description:
        'İlhamınızı Mizanplas.net ile paylaşın.',
    },
{
      id: 1,
      image:
        'https://res.cloudinary.com/dd4d48hwn/image/upload/v1682791174/mehmetaslanta%C5%9F_aojjvf.jpg',
      name: 'Mehmet ASLANTAŞ',
      title: 'Kurucu',
      description:
        'İlhamınızı Mizanplas.net ile paylaşın.',
    },
{
      id: 1,
      image:
        'https://res.cloudinary.com/dd4d48hwn/image/upload/v1701957876/ap_nozekg.jpg',
      name: 'Mehmet ASLANTAŞ',
      title: 'Kurucu',
      description:
        'İlhamınızı Mizanplas.net ile paylaşın.',
    },
{
      id: 1,
      image:
        'https://res.cloudinary.com/dd4d48hwn/image/upload/v1701957876/ap_nozekg.jpg',
      name: 'Mehmet ASLANTAŞ',
      title: 'Kurucu',
      description:
        'İlhamınızı Mizanplas.net ile paylaşın.',
    },
{
      id: 1,
      image:
        'https://res.cloudinary.com/dd4d48hwn/image/upload/v1701957876/ap_nozekg.jpg',
      name: 'Mehmet ASLANTAŞ',
      title: 'Kurucu',
      description:
        'İlhamınızı Mizanplas.net ile paylaşın.',
    },
{
      id: 1,
      image:
        'https://res.cloudinary.com/dd4d48hwn/image/upload/v1701957876/ap_nozekg.jpg',
      name: 'Mehmet ASLANTAŞ',
      title: 'Kurucu',
      description:
        'İlhamınızı Mizanplas.net ile paylaşın.',
    },
{
      id: 1,
      image:
        'https://res.cloudinary.com/dd4d48hwn/image/upload/v1701957876/ap_nozekg.jpg',
      name: 'Mehmet ASLANTAŞ',
      title: 'Kurucu',
      description:
        'İlhamınızı Mizanplas.net ile paylaşın.',
    },
{
      id: 1,
      image:
        'https://res.cloudinary.com/dd4d48hwn/image/upload/v1701957876/ap_nozekg.jpg',
      name: 'Mehmet ASLANTAŞ',
      title: 'Kurucu',
      description:
        'İlhamınızı Mizanplas.net ile paylaşın.',
    },
{
      id: 1,
      image:
        'https://res.cloudinary.com/dd4d48hwn/image/upload/v1701957876/ap_nozekg.jpg',
      name: 'Mehmet ASLANTAŞ',
      title: 'Kurucu',
      description:
        'İlhamınızı Mizanplas.net ile paylaşın.',
    },
    
  ];
  
  export default people;