import React from "react";
import { AiOutlineSearch } from "react-icons/ai";

export default function SearchButton({ onClick }) {
  

  return (
    <div>
      <div>
        <AiOutlineSearch
          className="cursor-pointer hover:text-gray-200 md:-translate-x-0 -translate-x-3 "
          size={48}
          onClick={ onClick }
        />
      </div>
    </div>
  );
}
