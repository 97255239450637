import React from "react";
import { RxHamburgerMenu } from "react-icons/rx";

export default function HamburgerButton({ onClick }) {
  return (
    <div>
      <div>
        <RxHamburgerMenu
          className="cursor-pointer hover:text-gray-200 duration-300 md:translate-x-0 translate-x-3 "
          size={48}
          onClick={ onClick }
        />
      </div>
    </div>
  );
}
