import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import { TbPointFilled } from "react-icons/tb";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { ImSpoonKnife } from "react-icons/im";
import { MdOutlineSlowMotionVideo } from "react-icons/md";
import { MdOutlineMenuBook } from "react-icons/md";
import { GiFlame } from "react-icons/gi";
import { FaBlog } from "react-icons/fa";
import { GiWinterGloves } from "react-icons/gi";

export default function NewRecipes() {
  const [recipes, setRecipes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [intervalId, setIntervalId] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        setLoading(true);
        const querySnapshot = await getDocs(collection(db, "recipes"));
        const recipeData = [];
        querySnapshot.forEach((doc) => {
          recipeData.push(doc.data());
        });
        setRecipes(recipeData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching recipes:", error);
      }
    };

    fetchRecipes();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex < recipes.length - 1) {
        setCurrentIndex(currentIndex + 1);
      } else {
        setCurrentIndex(0);
      }
    }, 4000);
    setIntervalId(interval);
    return () => clearInterval(interval);
  }, [currentIndex, recipes.length]);

  const handlePrevious = () => {
    clearInterval(intervalId);
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const handleNext = () => {
    clearInterval(intervalId);
    if (currentIndex < recipes.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
  };

  return (
    <div className="md:hidden w-full h-full">
      <div className="flex flex-row text-green-900 justify-center">
        <div className="translate-y-1 mr-2">
          <TbPointFilled size={35} />
        </div>
        <div className="text-4xl font-display2 mb-6">En Yeni Tarifler</div>
        <div className="translate-y-1 ml-2">
          <TbPointFilled size={35} />
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center">
          <div className="p-4 max-w-md">
            <div className="animate-pulse h-16 w-16 rounded-full bg-green-900 mx-auto"></div>
            <p className="text-center mt-4 text-gray-800 font-display">
              Yükleniyor...
            </p>
          </div>
        </div>
      ) : (
        <div style={{ position: "relative" }}>
          {recipes.length &&
            recipes.map((recipe, index) => {
              if (index === currentIndex) {
                return (
                  <a
                    href={`/tarif/${recipe.title}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div key={recipe.id} style={{ position: "relative" }}>
                      <img className="shadow-xl" src={recipe.image} />
                      <button
                        className="text-green-900"
                        onClick={handlePrevious}
                        style={{
                          position: "absolute",
                          top: 150,
                          left: 0,
                        }}
                      >
                        <FaChevronCircleLeft size={35} />
                      </button>
                      <button
                        className="text-green-900 "
                        onClick={handleNext}
                        style={{
                          position: "absolute",
                          top: 150,
                          right: 0,
                        }}
                      >
                        <FaChevronCircleRight size={35} />
                      </button>
                      <div
                        className="font-bold font-display2 text-white text-3xl "
                        style={{
                          position: "absolute",
                          bottom: 50,
                          left: 20,
                        }}
                      >
                        {recipe.title}
                      </div>
                    </div>
                  </a>
                );
              }
              return null;
            })}
          <div class="grid grid-cols-3 gap-0">
            <a href="/kategoriler">
              <div className="border border-white bg-red-600  h-16 flex flex-col text-white">
                <div className="flex justify-center mt-2 text-amber-400">
                  <ImSpoonKnife size={28} />
                </div>{" "}
                <div>TARİFLER</div>
              </div>
            </a>
            <a href="/videolu-tarifler">
              <div className="border border-white bg-red-600  h-16 flex flex-col text-white">
                <div className="flex justify-center mt-2 text-amber-400">
                  <MdOutlineSlowMotionVideo size={28} />
                </div>{" "}
                <div>VİDEOLAR</div>
              </div>
            </a>
            <a href="/günün-menüsü">
              <div className="border border-white bg-red-600  h-16 flex flex-col text-white">
                <div className="flex justify-center mt-2 text-amber-400">
                  <MdOutlineMenuBook size={28} />
                </div>{" "}
                <div>MENÜLER</div>
              </div>
            </a>
            <a href="/trend-yemek-tarfileri">
              <div className="border border-white bg-red-600  h-16 flex flex-col text-white">
                <div className="flex justify-center mt-2 text-amber-400">
                  <GiFlame size={28} />
                </div>{" "}
                <div>TREND</div>
              </div>
            </a>
            <a href="/blog">
              <div className="border border-white bg-red-600  h-16 flex flex-col text-white">
                <div className="flex justify-center mt-2 text-amber-400">
                  <FaBlog size={28} />
                </div>{" "}
                <div>BLOG</div>
              </div>
            </a>
            <a href="/kategoriler">
              <div className="border border-white bg-red-600  h-16 flex flex-col text-white">
                <div className="flex justify-center mt-2 text-amber-400">
                  <GiWinterGloves size={28} />
                </div>{" "}
                <div>NE PİŞİRSEM?</div>
              </div>
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
